<template lang="pug">
v-dialog(v-model="dialog", width="400")
  template(v-slot:activator="{ on }")
    IconBtn(@click="open", v-on="on", icon="mdi-information-outline", title="")
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Breakdown of Sales
    v-list(dense)
      v-list-item
        v-list-item-content
          v-list-item-title Breakfast
          v-list-item-subtitle Start to 11 AM
      v-divider
      v-list-item
        v-list-item-content
          v-list-item-title Lunch
          v-list-item-subtitle 11 AM to 4 PM
      v-divider
      v-list-item
        v-list-item-content
          v-list-item-title Dinner
          v-list-item-subtitle 4 PM to close
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
  },
};
</script>