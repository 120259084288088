<template lang="pug">
.s-vstack
  div
    DaySelector(@select="select")
  Table(:items="items", :orders="orders")
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import Table from "./Table";

export default {
  components: { Table },
  data() {
    return {
      date: null,
      items: [],
      orders: [],
    };
  },
  computed: { ...mapGetters(["biz"]) },
  methods: {
    async select(date) {
      this.date = date;
      // stripe intent use second instead of millisecond
      const begin = moment(this.date).startOf("day").unix();
      const end = moment(this.date).endOf("day").unix();
      const params = {
        criteria: {
          bizId: this.biz._id,
          created: { $gte: begin, $lt: end },
        },
      };
      this.items = await this.$api.stripe.paymentIntent.list(params);
      await this.loadOrders();
    },
    async loadOrders() {
      const ids = this.items.map((item) => item.orderId).filter(Boolean);
      const criteria = { _id: { $in: ids } };
      const select = "orderNumber";
      this.orders = await this.$api.order.list({ criteria, select });
    },
  },
};
</script>