<template lang="pug">
IconBtn(
  :loading="loading",
  @click="submit()",
  :title="title",
  :outlined="outlined"
)
</template>

<script>
export default {
  props: {
    accountId: { type: String, default: null }, // stripe account id
    compact: { type: Boolean, default: false },
  },
  data() {
    return { loading: false };
  },
  computed: {
    title() {
      return this.compact ? "Update" : "Update Stripe Account";
    },
    outlined() {
      return this.compact;
    },
  },
  methods: {
    async submit() {
      // get current url
      const href = window.location.href;
      if (!this.accountId) return;
      this.loading = true;
      const params = {
        accountId: this.accountId,
        refresh_url: href,
        return_url: href,
      };
      try {
        const { url } = await this.$api.stripe.accountLink.update(params);
        window.open(url, "_blank");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
