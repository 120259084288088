<template lang="pug">
v-dialog(v-model="dialog", width="400")
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Arrange Modifiers
      v-spacer
      v-btn(
        outlined,
        small,
        :loading="loading",
        color="secondary",
        @click="save"
      ) save
    simple-table
      tbody
        tr(
          v-for="(item, index) in modifiers",
          :key="index",
          draggable="true",
          @dragstart="drag(index, $event)",
          @dragend="dragend($event)",
          @drop="drop(index, $event)",
          @dragover.prevent=""
        )
          td
            v-icon(small) mdi-menu
          td
            .subtitle-2 {{ item.name }}
              v-icon.ml-2(color="success", small, v-if="item.required") mdi-check-circle
            .caption {{ item | modifierChoices }}
          td.text-right
            v-btn(icon, small, @click="removeAt(index)")
              v-icon(small, color="error") mdi-delete
            v-btn(icon, small, @click="editModifier(item)")
              v-icon(small, color="secondary") mdi-pencil
    v-card-text.pt-3
      .d-flex.flex-row.align-center
        v-text-field(
          v-model="searchText",
          label="Search to add modifiers",
          solo,
          hide-details
        )
        v-btn.ml-3.text-capitalize(
          outlined,
          small,
          color="secondary",
          @click="createModifier()"
        ) Create Modifier
    simple-table
      tbody
        tr(
          v-for="(item, index) in filteredModifiers",
          :key="index",
          draggable="true",
          @dragstart="drag(index, $event)",
          @dragend="dragend($event)",
          @drop="drop(index, $event)",
          @dragover.prevent=""
        )
          th
            .subtitle-2 {{ item.name }}
              v-icon.ml-2(color="success", small, v-if="item.required") mdi-check-circle
            .caption {{ item | modifierChoices }}
          td.text-right
            v-btn(
              x-small,
              rounded,
              outlined,
              color="secondary",
              @click="add(item)"
            )
              span Add
              v-icon(small, right) mdi-plus
</template>

<script>
import { EventBus } from "@/event-bus.js";
import _ from "underscore";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dish: null,
      dialog: false,
      loading: false,
      modifiers: [],
      searchText: "",
    };
  },
  computed: {
    ...mapGetters(["biz", "bizmodifiers"]),
    filteredModifiers() {
      if (!this.searchText || this.searchText.length < 2) return [];
      if (!this.dish) return [];
      const searchText = this.searchText.toLowerCase();
      return this.bizmodifiers
        ?.map((o) => o.modifier)
        ?.filter((o) => o.name.toLowerCase().indexOf(searchText) != -1);
    },
  },
  methods: {
    open(data) {
      this.dish = JSON.parse(JSON.stringify(data));
      if (!this.dish || !this.biz) return;
      this.dialog = true;
      this.searchText = "";
      const filtered = _.chain(this.bizmodifiers)
        .pluck("modifier")
        .reject((o) => !this.dish.modifierIDs.includes(o._id))
        .sortBy((o) => this.dish.modifierIDs.indexOf(o._id))
        .value();
      this.modifiers = JSON.parse(JSON.stringify(filtered));
    },
    drag(index, ev) {
      this.draggingIndex = index;
      ev.target.style.opacity = 0.5;
    },
    dragend: function (e) {
      e.target.style.opacity = 1;
    },
    drop: function (index) {
      if (index == this.draggingIndex) return;
      if (!this.modifiers) return;
      if (index < 0 || index >= this.modifiers.length) return;
      if (this.draggingIndex < 0 || this.draggingIndex >= this.modifiers.length)
        return;
      let modifiers = JSON.parse(JSON.stringify(this.modifiers));
      let tmp = JSON.parse(JSON.stringify(modifiers[this.draggingIndex]));
      modifiers.splice(this.draggingIndex, 1);
      modifiers.splice(index, 0, tmp);
      this.modifiers = modifiers;
    },
    add(modifier) {
      const some = this.modifiers.some((o) => o._id == modifier._id);
      if (some) {
        alert("Already in the list");
        return;
      }
      this.modifiers.push(modifier);
      this.searchText = "";
    },
    removeAt(index) {
      this.modifiers.splice(index, 1);
    },
    async save() {
      if (!this.biz || !this.dish) return;
      this.loading = true;
      const toAdd = this.modifiers
        .filter((o) => !this.dish.modifierIDs.includes(o._id))
        .map((o) => o._id);
      const toRemove = this.dish.modifierIDs.filter(
        (id) => !this.modifiers.some((modifier) => modifier._id == id)
      );
      const params = {
        criteria: { _id: this.biz._id, "dishes._id": this.dish._id },
        action: {
          $set: { "dishes.$.modifierIDs": _.pluck(this.modifiers, "_id") },
        },
      };
      try {
        const data = await this.$api.biz.update(params);
        this.$store.dispatch("setBiz", data);
        this.updateModifiers(toAdd, toRemove);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async updateModifiers(toAdd, toRemove) {
      if (!this.dish) return;
      const dishId = this.dish._id;
      if (toAdd?.length) {
        const params = {
          criteria: { "modifier._id": { $in: toAdd } },
          action: { $addToSet: { dishes: dishId } },
        };
        this.axios.post("/bizmodifiers/updateMulti", params);
      }
      if (toRemove?.length) {
        const params = {
          criteria: { "modifier._id": { $in: toRemove } },
          action: { $pull: { dishes: dishId } },
        };
        this.axios.post("/bizmodifiers/updateMulti", params);
      }
    },
    createModifier() {
      if (!this.dish) return;
      const data = {
        bizId: this.biz._id,
        dishes: [this.dish._id],
        modifier: {
          name: "",
          maxSelection: 1,
          choices: [],
          associatedChoices: [],
        },
      };
      EventBus.$emit("edit-modifier", data);
      this.dialog = false;
    },
    editModifier(item) {
      const found = this.bizmodifiers.find((o) => o.modifier._id == item._id);
      if (found) {
        this.dialog = false;
        EventBus.$emit("edit-modifier", found);
      }
    },
  },
};
</script>
