<template lang="pug">
tr(@click="$emit('click', item)", role="button")
  th
    v-icon(color="info", v-if="store") mdi-circle-small
    span {{ item.customer.name }}
  td {{ item.party_size }}
  td.text-right.nowrap {{ item.time | time(timezone) }}
  td.text-right {{ deposit | currency }}
  td.text-right(:class="redeemColor") {{ redeemed | currency }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    item: { type: Object, required: true },
    timezone: { type: String, default: null },
  },
  computed: {
    ...mapGetters(["booked_credits"]),
    store() {
      return this.item?.proxy == "back-office";
    },
    credits() {
      return this.booked_credits.filter((o) => o.booked_table == this.item._id);
    },
    deposit() {
      const subtotal = this.item?.payment?.subtotal;
      const refund =
        this.item.refunds?.reduce((acc, o) => acc + o.amount, 0) || 0;
      return subtotal - refund;
    },
    redeemed() {
      return this.credits.reduce((acc, o) => acc + o.amount, 0);
    },
    isPast() {
      return this.item.time < Date.now();
    },
    redeemColor() {
      if (this.item.status < 0) return "";
      if (!this.isPast) return "";
      if (this.redeemed > this.deposit) return "over-redeem";
      if (this.redeemed < this.deposit) return "under-redeem";
      return "redeem-ok";
    },
  },
};
</script>

<style lang="sass" scoped>
table td.over-redeeem
  color: red
table td.under-redeem
  color: orange
table td.redeem-ok
  color: green
</style>