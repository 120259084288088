<template lang="pug">
.s-vstack
  .s-card
    .s-vstack
      BizBasic(:biz="biz")
      v-divider
      BizAddress
      BizPhone
      BizEmail
  BizTaxRates
  ServiceItems
  Online
  Catering
  License
  .d-flex.flex-row
    Pricing.mr-3
    TestOrder
</template>

<script>
import { mapGetters } from "vuex";
import Catering from "./Catering";
import License from "./License";
import Pricing from "./Pricing";
import TestOrder from "./TestOrder";
import Online from "/libs/components/Biz/Online";

export default {
  components: { Online, Catering, License, Pricing, TestOrder },
  computed: { ...mapGetters(["biz"]) },
};
</script>
