<template lang="pug">
v-btn(icon, @click.stop="submit", :loading="loading")
  v-icon(:class="color", small) mdi-earth
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters(["biz"]),
    color() {
      return this.item.controlled
        ? "grey--text text--lighten-1"
        : "green--text";
    },
  },
  methods: {
    async submit() {
      if (!this.biz || !this.item) return;
      this.loading = true;
      try {
        const value = !(this.item.controlled == true);
        const params = {
          criteria: { _id: this.biz._id, "courses._id": this.item._id },
          action: { $set: { "courses.$.controlled": value } },
        };
        const data = await this.$api.biz.update(params);
        this.$store.dispatch("setBiz", data);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
