<template lang="pug">
v-dialog(v-model="dialog", max-width="500", scrollable)
  v-form(@submit.prevent="submit")
    v-card
      v-card-title Create Booked Table
      v-card-text
        v-text-field(v-model="customer.name", label="Name")
        PhoneField(v-model="customer.phone", label="Phone")
        v-text-field(v-model="customer.email", label="Email")
        DateTimePicker(
          v-model="time",
          date_label="Date",
          time_label="Time",
          :timezone="timezone"
        )
        v-text-field(
          v-model.number="party_size",
          label="Party Size",
          type="number"
        )
        .d-flex.flex-row.align-center
          v-select(
            v-model="booked_item.id",
            label="Item",
            :items="item_options"
          )
          RefreshSetting
        v-text-field(v-model="note", label="Note")
      v-card-actions
        v-btn(color="secondary", type="submit", :loading="loading") Submit
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import RefreshSetting from "./RefreshSetting";

export default {
  components: { RefreshSetting },
  data() {
    return {
      dialog: false,
      loading: false,
      customer: {
        name: "",
        email: "",
        phone: "",
      },
      time: Date.now(),
      party_size: 1,
      booked_item: {
        id: null,
        name: "",
        price: 0,
      },
      note: "",
      compensated: true,
    };
  },
  computed: {
    ...mapGetters(["biz", "rsvtsetting"]),
    timezone() {
      return this.biz?.address?.timezone;
    },
    item_options() {
      return (
        this.rsvtsetting?.items?.map((item) => ({
          text: item.name,
          value: item._id,
        })) || []
      );
    },
  },
  methods: {
    open() {
      this.time = moment().add(1, "hour").startOf("hour").valueOf();
      this.dialog = true;
    },
    validate() {
      if (!this.customer.name) throw new Error("Name is required");
      if (!this.customer.phone) throw new Error("Phone is required");
      const found = this.rsvtsetting.items.find(
        (o) => o._id === this.booked_item.id
      );
      if (!found) throw new Error("Item is required");
      this.booked_item.id = found?._id;
      this.booked_item.name = found?.name;
      this.booked_item.price = found?.price;
    },
    async submit() {
      const data = {
        seller: {
          id: this.biz._id,
          name: this.biz.name,
          address: this.biz.address,
        },
        customer: this.customer,
        time: this.time,
        party_size: this.party_size,
        booked_item: this.booked_item,
        note: this.note,
        compensated: this.compensated,
        proxy: "back-office",
      };
      this.loading = true;
      try {
        this.validate();
        const result = await this.$api.rs.book.custom("create_store", data);
        this.$store.dispatch("addBookedTable", result);
        this.$toast.success("Booking is created");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>