<template lang="pug">
v-dialog(v-model="dialog", width="600")
  v-card
    v-toolbar(dense, flat)
      .subtitle-2 Add Courses
      v-tooltip(right, v-if="courses.length > 1")
        template(v-slot:activator="{ on }")
          v-btn.text-notransform(
            @click="reset",
            icon,
            small,
            color="error",
            v-on="on"
          )
            v-icon(small) mdi-delete-sweep
        span Clear all items
      v-spacer
      IconBtn(@click.stop="more", icon="mdi-plus", title="Item")
    v-divider
    v-form(@submit.prevent="submit")
      simple-table
        thead
          tr
            th Name
            th(style="width: 150px") Classification
            th(style="width: 150px") P&L Group
            th(style="width: 80px")
        tbody
          tr(
            v-for="(item, index) in courses",
            :key="index",
            draggable="true",
            @dragstart="drag(index, $event)",
            @dragend="dragend($event)",
            @drop="drop(index, $event)",
            @dragover.prevent=""
          )
            td
              v-text-field(
                v-model="item.name",
                hide-details,
                single-line,
                dense,
                @keydown.down="more()"
              )
            td
              v-select(
                v-model="item.classification",
                :items="classification_items",
                hide-details,
                single-line,
                dense
              )
            td
              PNLPicker(v-model="item.grp", single-line, dense, hide-details)
            td.text-right
              v-icon.mr-3(
                color="error",
                small,
                v-if="courses.length > 1",
                @click.stop="less(index)"
              ) mdi-delete
              v-icon(small) mdi-menu
      v-card-text
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
        .pl-2.red--text(
          v-for="(error, index) in errors",
          :key="index + 'index'"
        ) {{ error }}
        .body-2.mt-2
          div Use <kbd>Down Arrow Key</kbd> on your keyboard to quickly add a new line.
          div Name: e.g., Appetizers
          div P&L Group: select or enter a profit & loss group name.
</template>

<script>
import { EventBus } from "@/event-bus.js";
import _ from "underscore";
import { mapGetters } from "vuex";
import CourseClassification from "/libs/json/CourseClassification.json";

export default {
  name: "MultiCourseForm",
  data() {
    return {
      courses: [],
      dialog: false,
      errors: [],
      loading: false,
      classification_items: JSON.parse(JSON.stringify(CourseClassification)),
      draggingIndex: null,
    };
  },
  computed: { ...mapGetters(["biz"]) },
  mounted() {
    EventBus.$on("multi-course-form", this.onForm);
  },
  destroyed() {
    EventBus.$off("multi-course-form", this.onForm);
  },
  methods: {
    onForm() {
      if (!this.courses?.length) this.reset();
      this.errors = [];
      this.dialog = true;
    },
    reset() {
      if (!this.biz) return;
      let grp = "Food";
      let classification = 0;
      if (this.biz.industry >= 100) {
        grp = "Merchandise";
        classification = 1;
      }
      this.courses = [
        { name: "", ooo: false, controlled: false, grp, classification },
      ];
    },
    less(index) {
      this.courses.splice(index, 1);
    },
    more() {
      const last = _.last(this.courses);
      if (last) {
        let clone = JSON.parse(JSON.stringify(last));
        clone.name = "";
        this.courses.push(clone);
      }
    },
    drag(index, ev) {
      this.draggingIndex = index;
      ev.target.style.opacity = 0.5;
    },
    dragend: function (e) {
      e.target.style.opacity = 1;
    },
    drop: function (index) {
      if (!this.courses) return;
      if (index == this.draggingIndex) return;
      if (index < 0 || index >= this.courses.length) return;
      if (this.draggingIndex < 0 || this.draggingIndex >= this.courses.length)
        return;
      let courses = JSON.parse(JSON.stringify(this.courses));
      let tmp = JSON.parse(JSON.stringify(courses[this.draggingIndex]));
      courses.splice(this.draggingIndex, 1);
      courses.splice(index, 0, tmp);
      this.courses = courses;
    },
    async submit() {
      this.errors = [];
      /// validate
      const baseIndex = this.biz.courses.length;
      this.courses.forEach((o, index) => (o.sortId = index + baseIndex));

      if (!this.biz) return (this.errors = ["Invalid data"]);

      _.each(this.courses, (course) => {
        course.name = course.name?.trim();
        course.grp = course.grp?.trim();
        if (!course.name?.length) this.errors.push("Name is required");
        const some = this.courses.some(
          (o) => o._id !== course._id && o.name === course.name
        );
        if (some) {
          const message = course.name + ": Duplicate name.";
          this.errors.push(message);
        }
      });
      if (this.errors && this.errors.length > 0) return;
      this.loading = true;
      const params = {
        criteria: { _id: this.biz._id },
        action: { $push: { courses: { $each: this.courses } } },
      };
      try {
        const data = await this.$api.biz.update(params);
        this.reset();
        this.$store.dispatch("setBiz", data);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
