<template lang="pug">
v-dialog(v-model="dialog", width="450")
  template(v-slot:activator="{ on }")
    v-list-item(outlined, small, color="secondary", v-on="on")
      span Set Bulk Menus
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Set All Items Menus
    v-divider
    v-form(@submit.prevent="save")
      v-card-text
        Note(:courseId="courseId")
        v-select(
          label="Menus (allow multiple)",
          v-model="menus",
          :items="menu_items",
          multiple,
          small-chips,
          deletable-chips,
          menu-props="auto"
        )
        v-btn(type="submit", block, color="secondary", :loading="loading") Save
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";
import Note from "./Note";

export default {
  components: { Note },
  props: {
    courseId: { type: String, default: null },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      menus: [],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    menu_items() {
      return (
        this.biz?.menus?.map((o) => {
          return { text: o.name, value: o._id };
        }) || []
      );
    },
  },
  methods: {
    async save() {
      if (!this.biz) return;
      this.loading = true;
      _.each(this.biz.dishes, (o) => {
        if (o.course == this.courseId) o.menus = this.menus;
      });
      const params = {
        criteria: { _id: this.biz._id },
        action: { $set: { dishes: this.biz.dishes } },
      };
      try {
        const data = await this.$api.biz.update(params);
        this.$store.dispatch("setBiz", data);
        this.menus = [];
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
