<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="order")
    v-toolbar(flat, dense)
      .subtitle-2 Event Order
    table.clean-table
      tbody
        tr(v-if="order.user")
          td Customer
          td {{ order.user.name }} {{ order.user.phone | phone }}
        tr(v-if="order.user")
          td Email
          td {{ order.user.email }}
        tr
          td Channel
          td {{ order.proxy }}
    v-divider
    simple-table
      thead
        tr
          th Item
          th.text-right Price
          th.text-right Quantity
      tbody
        tr(v-for="(item, index) in order.items", :key="index")
          td {{ item.name }}
          td {{ item.price | currency }}
          td {{ item.quantity }}
    table.clean-table
      tbody
        tr
          th Subtotal
          td {{ order.payment.subtotal | currency }}
        tr
          th Tax
          td {{ order.payment.tax | currency }}
        tr
          th Total
          td {{ order.payment.total | currency }}
    table.clean-table
      thead
        tr
          th Payment
          th.text-right Amount
      tbody
        tr(
          v-for="(item, index) in order.payment.charges",
          :key="index + 'charge'"
        )
          td {{ item.method }}
          td.text-right {{ item.amount | currency }}
      //- v-card-actions
      //-   v-btn(@click="fixCharge", v-if="order.status != false") Fix Charge
    v-card-text
      ClickToCopy(:text="order._id")
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  data() {
    return {
      dialog: false,
      order: null,
    };
  },
  mounted() {
    EventBus.$on("show-event-order", this.open);
  },
  destroyed() {
    EventBus.$off("show-event-order", this.open);
  },
  methods: {
    async open(order_id) {
      if (!order_id) return;
      this.dialog = true;
      try {
        this.order = await this.$api.event.order.retrieve({
          order_id: order_id,
        });
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    async fixCharge() {
      if (!this.order) return;
      const params = { order_id: this.order._id };
      try {
        this.order = await this.$api.event.order.custom("fixCharge", params);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>