<template lang="pug">
v-dialog(v-model="dialog", max-width="400px", scrollable)
  template(v-slot:activator="{ on }")
    v-avatar(role="button", v-on="on")
      v-img(:src="mediaHost + item.picLink", v-if="item.picLink")
      v-icon(v-else) mdi-image
  v-card(v-if="item")
    v-toolbar(flat, dense)
      .subtitle-2 Edit Picture: {{ item.name }}
    v-card-text
      v-col(align="center")
        croppa(
          v-model="myCroppa",
          :width="170",
          :height="170",
          :quality="2",
          :canvas-color="canvas_color",
          initial-size="contain"
        )
        .caption Use mouse to move and zoom.
        .subtitle-2.my-3 Custom Background Color
        v-color-picker(
          v-model="canvas_color",
          hide-mode-switch,
          hide-input,
          hide-canvas,
          flat,
          show-swatches,
          mode="hexa",
          disabled
        )
    v-card-actions
      v-btn(
        color="secondary",
        block,
        @click.stop="generateBlob",
        :loading="loading"
      ) save
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      mediaHost: process.env.VUE_APP_MEDIAHOST,
      dialog: false,
      myCroppa: {},
      loading: false,
      canvas_color: "#FFFFFF",
    };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  methods: {
    generateBlob() {
      this.myCroppa.generateBlob(
        (blob) => {
          if (!blob) return;
          this.upload(blob);
        },
        "image/jpeg",
        0.8
      );
    },
    async upload(blob) {
      if (!this.biz || !this.biz._id || !this.item || !this.item._id) return;
      const bizId = this.biz._id;
      const dishId = this.item._id;
      this.loading = true;
      const formData = new FormData();
      formData.append("file", blob);
      formData.append("bizId", bizId);
      formData.append("dishId", dishId);
      try {
        const result = await this.$api.b.action.custom(
          "updateDishPicture",
          formData
        );
        this.$store.dispatch("setBiz", result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
