<template lang="pug">
div
  .subtitle-2.info--text Availability
  div(v-if="item.time_slots?.length")
    .subtitle-2 Time Slots:
      span.ml-2(v-for="(slot, index) in item.time_slots", :key="index") {{ slot | minutesToTime }}
  div(v-else)
    .subtitle-2 Time Gap: {{ item.time_gap | leadtime }}
    .subtitle-2 Last Booking Time: {{ item.last_booking_time | leadtime }} before closing
  div(v-if="item.weekdays?.length")
    .subtitle-2 Limited to:
      span.ml-2(v-for="(day, index) in item.weekdays", :key="index") {{ day | day }}
  .subtitle-2(
    v-if="item.start_date || item.end_date",
    :class="timeframe_color"
  ) Timeframe: {{ timeframe }}
</template>

<script>
import moment from "moment";

export default {
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    timeframe() {
      const start_date = this.item?.start_date;
      const end_date = this.item?.end_date;
      let status = "";
      let text = "";
      if (start_date) {
        text += "From " + moment(start_date).format("MMM D, YYYY");
      }
      if (end_date) {
        let name = moment(end_date).format("MMM D, YYYY");
        if (start_date) {
          if (start_date == end_date) {
            text = "On " + name;
          } else {
            text += " to " + name;
          }
        } else text += "Until " + name;
      }
      // calculate status
      const start_ms = moment(start_date).startOf("day").valueOf();
      const end_ms = moment(end_date).endOf("day").valueOf();
      const now_ms = new Date().getTime();
      if (start_ms && start_ms > now_ms) {
        if (end_ms && end_ms < now_ms) {
          status = "Past";
        } else {
          status = "Coming Soon";
        }
      }
      if (end_ms && end_ms < now_ms) {
        status = "Past";
      }
      status = status || "Available";
      const result = text + " (" + status + ")";
      return result.trim();
    },
    timeframe_color() {
      const now = moment();
      // error if end_date is past
      if (this.item.end_date && moment(this.item.end_date).isBefore(now)) {
        return "error--text";
      }

      // warning if start_date is in the future
      if (this.item.start_date && moment(this.item.start_date).isAfter(now)) {
        return "warning--text";
      }

      // warning if end_date is less than booking window
      const cutoff = moment().add(this.item.days_in_advance_max, "days");
      if (this.item.end_date && moment(this.item.end_date).isBefore(cutoff)) {
        return "warning--text";
      }

      return "info--text";
    },
  },
};
</script>