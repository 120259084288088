<template lang="pug">
v-btn(icon, color="error", @click.stop="handleRemove()", :loading="loading")
  v-icon(small) mdi-delete
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  methods: {
    async handleRemove() {
      this.loading = true;
      try {
        const params = { $pull: { pricings: { _id: this.item._id } } };
        const result = await this.$api.biz.put(this.biz._id, params);
        this.$store.dispatch("setBiz", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>