<template lang="pug">
div
  IconBtn(
    @click="open()",
    title="Upload Poster",
    icon="mdi-image",
    :loading="loading"
  )
  v-dialog(v-model="dialog", max-width="600")
    v-card(flat)
      v-toolbar(flat, dense)
        .subtitle-2 Upload Poster
      v-form(@submit.prevent="save")
        v-card-text
          v-file-input(
            show-size,
            accept="image/*",
            label="Upload File",
            hint="Accept Image File. Limited to 5MB",
            persistent-hint,
            v-model="file",
            :loading=loading
          )
        v-card-actions
          v-btn(type="submit", block, color="secondary", :loading="loading") Submit
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["item"],
  data() {
    return {
      dialog: false,
      file: null,
      loading: false,
    };
  },
  methods: {
    ...mapActions(["updateEvent"]),
    open() {
      this.dialog = true;
    },
    async save() {
      if (!this.item) return;
      if (!this.file) {
        alert("A valid file is required");
        return;
      }
      if (this.file.size > 5242880) {
        alert("Please provide a file with size less than 5MB");
        return;
      }
      this.loading = true;
      const formData = new FormData();
      formData.append("event_id", this.item._id);
      formData.append("file", this.file);
      try {
        const url = "/event/entries/uploadPoster";
        const { data } = await this.axios.post(url, formData);
        this.updateEvent(data);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

