<template lang="pug">
v-dialog(v-model="dialog", width="450")
  template(v-slot:activator="{ on }")
    v-list-item(outlined, small, color="secondary", v-on="on")
      span Move to New Course
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Move All Items to a New Course
    v-divider
    v-form(@submit.prevent="save")
      v-card-text
        Note(:courseId="courseId")
        v-select(v-model="newCourseId", label="New Course", :items="items")
        v-btn(type="submit", block, color="secondary", :loading="loading") Save
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";
import Note from "./Note";

export default {
  components: { Note },
  props: {
    courseId: { type: String, default: null },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      newCourseId: null,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    items() {
      return this.biz?.courses
        .filter((o) => o._id != this.courseId)
        .map((o) => ({ text: o.name, value: o._id }));
    },
  },
  methods: {
    async save() {
      if (!this.biz) return;
      if (!this.newCourseId) return;
      this.loading = true;
      _.each(this.biz.dishes, (o) => {
        if (o.course == this.courseId) o.course = this.newCourseId;
      });
      try {
        const action = { $set: { dishes: this.biz.dishes } };
        const data = await this.$api.biz.put(this.biz._id, action);
        this.$store.dispatch("setBiz", data);
        this.newCourseId = null;
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
