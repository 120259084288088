
<template lang="pug">
v-btn(icon, color="secondary", @click="loadSetting", :loading="loading")
  v-icon(small) mdi-refresh
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz", "rsvtsetting"]),
  },
  mounted() {
    if (!this.rsvtsetting?._id) this.loadSetting();
  },
  methods: {
    async loadSetting() {
      const bizId = this.biz?._id;
      if (!bizId) {
        this.$store.dispatch("setRsvtSetting", {});
        return;
      }
      this.loading = true;
      const criteria = { _id: bizId };
      const result = await this.$api.b.rsvtsetting
        .retrieve({ criteria })
        .catch(() => null);
      this.$store.dispatch("setRsvtSetting", result || {});
      this.loading = false;
    },
  },
};
</script>