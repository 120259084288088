<template lang="pug">
div
  v-btn.text-capitalize(
    @click="open()",
    outlined,
    small,
    depressed,
    :loading="loading",
    color="grey darken-2"
  ) Upload
  v-dialog(v-model="dialog", max-width="600")
    v-card(flat)
      v-form(@submit.prevent="save")
        v-card-text
          v-select(
            label="File Type",
            v-model="file_type",
            :items="file_type_items"
          )
          v-file-input(
            show-size,
            accept="image/*",
            label="Upload File",
            hint="Accept Image or PDF File. Limited to 5MB",
            persistent-hint,
            v-model="file",
            :loading=loading
          )
        v-card-actions
          v-btn(type="submit", block, color="secondary", :loading="loading") Submit
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      file: null,
      file_type: "Business License",
      file_type_items: ["Business License", "Liquor License"],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["bizDocuments", "biz"]),
  },
  methods: {
    ...mapActions(["addBizDocument"]),
    open() {
      this.dialog = true;
    },
    validate() {
      if (!this.file) throw new Error("A valid file is required");
      if (this.file.size > 5242880)
        throw new Error("Please provide a file with size less than 5MB");
    },
    async save() {
      if (!this.biz) return;
      this.loading = true;
      try {
        this.validate();
        const formData = new FormData();
        formData.append("biz", this.biz._id);
        formData.append("biz_name", this.biz.name);
        formData.append("file_type", this.file_type);
        formData.append("file", this.file);
        const result = await this.$api.b.document.create(formData);
        this.addBizDocument(result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

