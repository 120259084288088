<template lang="pug">
v-dialog(v-model="dialog", width="700", scrollable)
  v-form(@submit.prevent="submit")
    v-card(v-if="entry")
      v-toolbar(dense, flat)
        .subtitle-2 {{ title }} Event
        v-spacer
        IconBtn(@click.stop="more()", title="Item", icon="mdi-plus")
      v-card-text
        v-text-field(
          label="Tax Rate",
          v-model.number="entry.tax_rate",
          suffix="%"
        )
        simple-table
          thead
            tr
              th Ticket Name
              th(style="width: 150px") Price
              th(style="width: 150px") Quantity
              th(style="width: 80px")
          tbody
            tr(
              v-for="(item, index) in entry.items",
              :key="index",
              draggable="true",
              @dragstart="drag(index, $event)",
              @dragend="dragend($event)",
              @drop="drop(index, $event)",
              @dragover.prevent=""
            )
              td
                v-text-field(v-model="item.name", single-line, hide-details)
              td
                v-text-field(
                  v-model.number="item.price",
                  prefix="$",
                  single-line,
                  hide-details
                )
              td
                v-text-field(
                  label="Quantity",
                  v-model.number="item.total_quantity",
                  step="1",
                  single-line,
                  hide-details
                )
              td.text-right
                v-icon.mr-3(
                  color="error",
                  small,
                  v-if="entry.items.length > 1",
                  @click.stop="less(index)"
                ) mdi-delete
                v-icon(small) mdi-menu
        .subtitle-2.my-3 Event Time
        v-row
          v-col(cols="6")
            DateTimePicker(
              v-model="entry.event_time.begin",
              date_label="Start"
            )
          v-col(cols="6")
            DateTimePicker(v-model="entry.event_time.end", date_label="End")
        .subtitle-2.my-3 Sale Time
        v-row
          v-col(cols="6")
            DateTimePicker(v-model="entry.sale_time.begin", date_label="Start")
          v-col(cols="6")
            DateTimePicker(v-model="entry.sale_time.end", date_label="End")
        v-text-field(label="Title", v-model="entry.title")
        quill-editor(
          v-model="entry.detail",
          ref="myQuillEditor",
          :options="editorOption"
        )
        v-text-field(
          v-model="entry.external_policy",
          label="Policy Website (optional)"
        )
      v-card-actions
        v-btn(
          block,
          color="secondary",
          type="submit",
          :loading="loading",
          :disabled="!isValid"
        ) Save
</template>

<script>
import { EventBus } from "@/event-bus.js";
import moment from "moment-timezone";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import _ from "underscore";
import { quillEditor } from "vue-quill-editor";
import { mapActions, mapGetters } from "vuex";
const { convert } = require("html-to-text");

const toolbarOptions = [
  ["bold", "italic", "underline"], // toggled buttons
  ["blockquote", "code-block"],
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ align: [] }],
  ["clean"], // remove formatting button
];

export default {
  name: "EventForm",
  components: { quillEditor },
  data() {
    return {
      entry: null,
      dialog: false,
      loading: false,
      editorOption: {
        modules: {
          toolbar: toolbarOptions,
        },
      },
      now: moment().format("YYYY-MM-DD"),
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    title() {
      if (this.entry?._id) return "Edit an";
      else return "Create a New";
    },
    isValid() {
      if (!this.entry?.items?.length) return;
      const some = this.entry.items.some((o) => o.price <= 0 || !o.name);
      if (some) return false;
      return this.isValidHttpUrl && this.entry.title;
    },
    isValidHttpUrl() {
      if (
        !this.entry ||
        !this.entry.external_policy ||
        !this.entry.external_policy.length
      )
        return true;
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(this.entry.external_policy);
    },
  },
  mounted() {
    EventBus.$on("edit-event", this.open);
  },
  destroyed() {
    EventBus.$off("edit-event", this.open);
  },
  methods: {
    ...mapActions(["addEvent", "updateEvent"]),
    open(data) {
      if (!data) {
        this.entry = {
          biz_id: this.biz._id,
          title: "",
          detail: "",
          items: [{ name: "", price: 100, total_quantity: 9999 }],
          event_time: {
            begin: moment().valueOf(),
            end: moment().valueOf(),
          },
          sale_time: {
            begin: moment().valueOf(),
            end: moment().valueOf(),
          },
        };
      } else {
        this.entry = JSON.parse(JSON.stringify(data));
        if (!this.entry.items || !this.entry.items.length) {
          this.entry.items = [{ name: "", price: 100, total_quantity: 9999 }];
        }
      }
      if (!this.entry.address) this.entry.address = this.biz.address;
      this.dialog = true;
    },
    async submit() {
      if (!this.entry) return;
      this.entry.detail_short = convert(this.entry.detail, {
        wordwrap: null,
      })
        .replace(/(?:\r\n|\r|\n)/g, " ") // remove newline
        .replace(/\s+/g, " ") // remove extra spacer
        .slice(0, 280);
      this.entry.total_quantity = Math.round(this.entry.total_quantity);
      this.loading = true;
      try {
        if (!this.entry._id) await this.create();
        else await this.update();
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async update() {
      if (!this.entry._id) return;
      const params = {
        event_id: this.entry._id,
        action: {
          $set: {
            title: this.entry.title,
            detail_short: this.entry.detail_short,
            detail: this.entry.detail,
            tax_rate: this.entry.tax_rate || 0,
            address: this.entry.address,
            items: this.entry.items,
            event_time: this.entry.event_time,
            sale_time: this.entry.sale_time,
            external_policy: this.entry.external_policy,
          },
        },
      };
      const result = await this.$api.event.entry.update(params);
      this.updateEvent(result);
    },
    async create() {
      if (this.entry._id) return;
      const event = await this.$api.event.entry.create(this.entry);
      this.addEvent(event);
    },
    less(index) {
      this.entry.items.splice(index, 1);
    },
    more() {
      const last = _.last(this.entry.items);
      if (last) {
        let clone = JSON.parse(JSON.stringify(last));
        delete clone._id;
        clone.name = "";
        this.entry.items.push(clone);
      }
    },
    drag(index, ev) {
      this.draggingIndex = index;
      ev.target.style.opacity = 0.5;
    },
    dragend: function (e) {
      e.target.style.opacity = 1;
    },
    drop: function (index) {
      if (!this.entry.items) return;
      if (index == this.draggingIndex) return;
      if (index < 0 || index >= this.entry.items.length) return;
      if (
        this.draggingIndex < 0 ||
        this.draggingIndex >= this.entry.items.length
      )
        return;
      let entry_items = JSON.parse(JSON.stringify(this.entry.items));
      let tmp = JSON.parse(JSON.stringify(entry_items[this.draggingIndex]));
      entry_items.splice(this.draggingIndex, 1);
      entry_items.splice(index, 0, tmp);
      this.entry.items = entry_items;
    },
  },
};
</script>
