<template lang="pug">
.s-vstack
  .s-vstack(v-for="(item, index) in locations", :key="index")
    Location(:location="item", :readers="readers", @created="load")
  div(v-if="showCreateLocation")
    v-btn(@click="handleCreateLocation", color="secondary", small) Create Location
  Transactions
  ReaderDetail
  CreateLocation(ref="createLocation", @created="load")
</template>

<script>
import { mapGetters } from "vuex";
import Location from "./Location";
import ReaderDetail from "./ReaderDetail";
import Transactions from "./Transactions";
import CreateLocation from "/libs/components/Stripe/Terminal/CreateLocation";

export default {
  components: { Location, ReaderDetail, Transactions, CreateLocation },
  data() {
    return {
      loading: false,
      locations: [],
      readers: [],
    };
  },
  computed: {
    ...mapGetters(["stripeAccount", "biz"]),
    showCreateLocation() {
      if (this.loading) return false;
      if (this.locations.length) return false;
      return true;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    handleCreateLocation() {
      this.$refs.createLocation.open(this.biz);
    },
    async load() {
      if (!this.biz) return;
      this.loading = true;
      try {
        const params = { bizId: this.biz._id };
        this.locations = await this.$api.stripe.terminalLocation.list(params);
      } catch (e) {
        //
      }
      for (var i = 0; i < this.locations.length; i++) {
        const params = {
          location: this.locations[i].terminal_id,
        };
        try {
          const result = await this.$api.stripe.terminalReader.list(params);
          this.readers = this.readers.concat(result);
        } catch (e) {
          this.$toast.error(e.response?.data || e.message);
        }
      }
      this.loading = false;
    },
  },
};
</script>