<template lang="pug">
v-switch(
  v-if="visible",
  :value="bizservice?.third_party?.merge_menu",
  @change="toggle",
  label="Combine Menus",
  :hint="hint",
  persistent-hint,
  :loading="loading"
)
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      hint: "Whether to combine multiple menus into one to simplify the menu structure for third-party platforms.",
    };
  },
  computed: {
    ...mapGetters(["bizservice", "biz"]),
    visible() {
      return this.biz.menus?.length > 1;
    },
  },
  methods: {
    // update third_parth.merge_menu to opposite value
    async toggle() {
      const value = this.bizservice?.third_party?.merge_menu;
      this.loading = true;
      try {
        const params = {
          criteria: { _id: this.bizservice._id },
          action: { $set: { "third_party.merge_menu": value } },
        };
        const result = await this.$api.service.account.update(params);
        this.$store.dispatch("updateBizService", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>