<template lang="pug">
.s-vstack
  v-card(flat, :loading="loading")
    v-toolbar(flat, dense)
      .subtitle-2 Courses
      v-spacer
      IconBtn(@click="create", title="Add", icon="mdi-plus")
    simple-table
      thead
        tr
          th Name
          th Tax
          th Classification
          th P&L Group
          th.nowrap # Items
          th
      tbody
        tr(
          v-for="(item, index) in biz.courses",
          :key="index",
          @click="edit(item)",
          role="button",
          draggable="true",
          @dragstart="drag(index, $event)",
          @dragend="dragend($event)",
          @drop="drop(index, $event)",
          @dragover.prevent=""
        )
          th
            .d-flex.flex-row
              v-icon.mr-2(x-small) mdi-menu
              div
                .nowrap {{ item.name }}
                .caption.text--secondary {{ item.description }}
              v-chip.ml-2(v-if="item.ooo", color="error", x-small) 86'd
          td(style="white-space: normal")
            Tax(:course="item")
          td {{ item.classification | courseClass }}
          td {{ item.grp }}
          td {{ countDish(item._id) }}
          td.text-right
            ToggleOnline(:item="item")
            ToggleThirdParty(:item="item")
            DeleteOne(:item="item")
  .body-2 <b>Courses</b> form the second tier of the hierarchy and usually fall under menus, encompassing categories like <b>Appetizers</b>, <b>Salads</b>, <b>Sandwiches</b>, and <b>Desserts</b>.
  Pricings(:biz="biz")
  Form
  MultiForm
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";
import Form from "./CourseForm";
import DeleteOne from "./DeleteOne";
import MultiForm from "./MultiCourseForm";
import Tax from "./Tax";
import ToggleOnline from "./ToggleOnline";
import ToggleThirdParty from "./ToggleThirdParty";
import Pricings from "/libs/components/Biz/Pricings";

export default {
  components: {
    Tax,
    Pricings,
    Form,
    MultiForm,
    ToggleOnline,
    ToggleThirdParty,
    DeleteOne,
  },
  data() {
    return { loading: false };
  },
  computed: { ...mapGetters(["biz"]) },
  methods: {
    countDish(courseId) {
      return this.biz?.dishes?.filter((o) => o.course == courseId)?.length;
    },
    drag(index, ev) {
      this.draggingIndex = index;
      ev.target.style.opacity = 0.5;
    },
    dragend: function (e) {
      e.target.style.opacity = 1;
    },
    drop: async function (index) {
      if (index == this.draggingIndex) return;
      if (!this.biz || !this.biz.courses) return;
      if (index < 0 || index >= this.biz.courses.length) return;
      if (
        this.draggingIndex < 0 ||
        this.draggingIndex >= this.biz.courses.length
      )
        return;
      this.loading = true;
      let courses = JSON.parse(JSON.stringify(this.biz.courses));
      let tmp = JSON.parse(JSON.stringify(courses[this.draggingIndex]));
      courses.splice(this.draggingIndex, 1);
      courses.splice(index, 0, tmp);
      courses.forEach((o, i) => {
        o.sortId = i;
      });
      const action = { $set: { courses } };
      const data = await this.$api.biz.put(this.biz._id, action);
      this.$store.dispatch("setBiz", data);
      this.loading = false;
    },
    create() {
      if (!this.biz) return;
      EventBus.$emit("multi-course-form");
    },
    edit(item) {
      EventBus.$emit("edit-course", item);
    },
    closeMultiForm() {
      this.toCreate = false;
    },
  },
};
</script>
