<template lang="pug">
v-card(v-if="stripeAccount", flat, :loading="loading")
  v-toolbar(flat, dense)
    .subtitle-2 Merchant-Service Account
    v-spacer
    v-btn(icon, small, color="green", :loading="loading", @click="load()")
      v-icon(small) sync
  .item-container(v-if="stripeAccount")
    LineItem(title="Country", :detail="stripeAccount.country")
    LineItem(title="Currency", :detail="stripeAccount.default_currency")
    LineItem(title="Type", :detail="stripeAccount.business_type")
    LineItem(title="Name", :detail="name")
    LineItem(
      title="Status",
      :detail="stripeAccount.charges_enabled ? 'Enabled' : 'Disabled'",
      :color="stripeAccount.charges_enabled ? '' : 'error--text'"
    )
    LineItem(title="Payout", :detail="payout", :color="payoutColor")
    LineItem(
      title="Requirements",
      :detail="currently_due",
      v-if="!isComplete",
      color="error--text"
    )
    LineItem(
      title="Future Requirements",
      :detail="future_due",
      v-if="future_due",
      color="error--text"
    )
    .text-center(v-if="!isComplete")
      AccountLinkUpdate(:accountId="stripeAccount.id")
</template>

<script>
import { mapGetters } from "vuex";
import LineItem from "./LineItem";

export default {
  components: { LineItem },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["stripeAccount", "biz"]),
    name() {
      if (!this.stripeAccount) return "";
      if (this.stripeAccount.business_type == "individual") {
        const first = this.stripeAccount.individual.first_name;
        const last = this.stripeAccount.individual.last_name;
        return `${first} ${last}`;
      }
      return this.stripeAccount.company?.name;
    },
    payout() {
      const schedule = this.stripeAccount.settings?.payouts?.schedule;
      if (!schedule) return "Not Enabled";
      let text = "";
      if (schedule.interval == "manual") text = "Manual";
      if (schedule.interval == "daily") text = "Daily";
      if (schedule.interval == "weekly")
        text = "Weekly/" + schedule.weekly_anchor;
      if (schedule.interval == "monthly")
        text = "Monthly/" + schedule.monthly_anchor;
      if (this.stripeAccount.payouts_enabled) return text;
      return text + " (Disabled)";
    },
    payoutColor() {
      if (!this.stripeAccount.payouts_enabled) return "error--text";
      return "";
    },
    currently_due() {
      return this.getDueItems(this.stripeAccount?.requirements?.currently_due);
    },
    future_due() {
      return this.getDueItems(
        this.stripeAccount?.future_requirements?.currently_due
      );
    },
    isComplete() {
      return (
        this.currently_due.length == 0 || this.currently_due == "Bank Account"
      );
    },
  },
  methods: {
    getDueItems(data) {
      if (!data) return;
      const result = data.map((item) => {
        if (item.startsWith("person") || item.startsWith("company")) {
          let items = item.split(".");
          if (items.length >= 2) return items[1];
        }
        if (item == "individual.verification.document") return "Photo ID";
        if (item == "individual.verification.additional_document")
          return "Additional Document";
        if (item == "tos_acceptance.date") return "Terms of Service";
        if (item == "tos_acceptance.ip") return "Terms of Service";
        if (item == "external_account") return "Bank Account";
        return item;
      });
      const unique = [...new Set(result)];
      return unique.join(", ");
    },
    async load() {
      if (!this.biz) {
        this.setStripeAccount();
        return;
      }
      this.loading = true;
      try {
        const params = { bizId: this.biz._id };
        const result = await this.$api.stripe.account.retrieve(params);
        this.$store.dispatch("setStripeAccount", result);
        this.updateBiz({ account: result });
      } catch (e) {
        this.$store.dispatch("setStripeAccount");
      }
      this.loading = false;
    },
    async updateBiz({ account }) {
      if (!account) return;
      if (!this.biz) return;
      const criteria = { _id: this.biz._id };
      const {
        charges_enabled,
        payouts_enabled,
        settings,
        requirements,
        future_requirements,
      } = account;
      const statement_descriptor =
        settings?.payments?.statement_descriptor || "";
      const current_deadline =
        requirements?.current_deadline ||
        future_requirements?.current_deadline ||
        null;

      // skip if no changes
      if (
        this.biz.payments?.charges_enabled == charges_enabled &&
        this.biz.payments?.payouts_enabled == payouts_enabled &&
        this.biz.payments?.statement_descriptor == statement_descriptor &&
        this.biz.payments?.current_deadline == current_deadline
      )
        return;

      const action = {
        $set: {
          "payments.statement_descriptor": statement_descriptor,
          "payments.current_deadline": current_deadline,
          "payments.charges_enabled": charges_enabled,
          "payments.payouts_enabled": payouts_enabled,
        },
      };
      try {
        const result = await this.$api.biz.update({ criteria, action });
        this.$store.dispatch("setBiz", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.item-container
  padding: 12px
  display: flex
  flex-direction: column
  row-gap: 6px
</style>