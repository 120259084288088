<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  v-form(ref="form", @submit.prevent="submit")
    v-card
      v-card-title Create Reader
      v-card-text
        v-text-field(v-model="label", label="Label", required)
        v-text-field(
          v-model="registration_code",
          label="Registration Code",
          required
        )
        p Use this form to connect a
          a.mx-1(:href="doc", target="_blank") BBPOS WisePOS E
          | reader to your Stripe account. Please refer to the documentation for more information.
        p A Stripe Reader (white bluetooth device) do not require this form. They can be connected directly in the POS app.
      v-card-actions
        v-btn(type="submit", color="secondary", :loading="loading") Create
</template>

<script>
export default {
  props: {
    location: { type: String, required: true },
  },
  data() {
    return {
      dialog: false,
      doc: "https://docs.stripe.com/terminal/payments/setup-reader/bbpos-wisepos-e",
      label: "",
      registration_code: "",
      loading: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
      this.label = "";
      this.registration_code = "";
    },
    async submit() {
      this.loading = true;
      try {
        console.log(this.location, this.label, this.registration_code);
        const params = {
          location: this.location,
          label: this.label,
          registration_code: this.registration_code,
        };
        await this.$api.stripe.terminalReader.create(params);
        this.$toast.success("Reader created");
        this.dialog = false;
        this.$emit("created");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    validate() {
      if (!label) throw new Error("Label is required");
      if (!registration_code) throw new Error("Registration Code is required");
    },
  },
};
</script>