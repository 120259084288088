<template lang="pug">
v-row.my-10(justify="center")
  v-card(outlined, max-width="600")
    .text-center.ma-5
      .title.my-3 BE IN
      .display-2.my-3 TOUCH
      div Please fill your inquiry details below
    v-form(@submit.prevent="submit")
      v-card-text
        v-row(wrap)
          v-col(cols="12", md="4")
            v-text-field(
              label="Name",
              v-model="name",
              :error-messages="nameErrors",
              required,
              @blur="$v.name.$touch()"
            )
          v-col(cols="12", md="4")
            v-text-field(
              label="Email",
              v-model="email",
              :error-messages="emailErrors",
              required,
              @blur="$v.email.$touch()"
            )
          v-col(cols="12", md="4")
            PhoneField(
              v-model="phone",
              :error-messages="phoneErrors",
              @blur="$v.phone.$touch()"
            )
        v-text-field(
          label="Business Name",
          v-model="biz_name",
          :error-messages="bizNameErrors",
          required,
          @blur="$v.biz_name.$touch()"
        )
        v-text-field(label="Subject", v-model="subject", placeholder="Subject")
        v-textarea(
          label="Content",
          v-model="content",
          :error-messages="contentErrors",
          @blur="$v.content.$touch()",
          required
        )
        v-btn(
          color="secondary",
          block,
          :loading="loading",
          :disabled="$v.$invalid",
          type="submit"
        ) Send
        v-alert.mt-5(:type="alertType", dismissible, :value="sent") {{ alertText }}
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  email,
  maxLength,
  minLength,
  required,
} from "vuelidate/lib/validators";

export default {
  name: "Contact",
  mixins: [validationMixin],
  validations: {
    name: { required },
    email: { required, email },
    phone: { required, maxLength: maxLength(10) },
    biz_name: { required },
    content: { required, minLength: minLength(20) },
  },
  data() {
    return {
      name: "",
      biz_name: "",
      email: "",
      phone: "",
      subject: "",
      content: "",
      loading: false,
      sent: false,
      alertType: "success",
      alertText: "",
    };
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      if (!this.$v.name.required) errors.push("Name is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      if (!this.$v.email.email) errors.push("Must be valid e-mail");
      if (!this.$v.email.required) errors.push("E-mail is required");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      if (!this.$v.phone.required) errors.push("Phone is required");
      if (!this.$v.phone.maxLength) errors.push("Phone must be 10 digits");
      return errors;
    },
    bizNameErrors() {
      const errors = [];
      if (!this.$v.biz_name.$dirty) return errors;
      if (!this.$v.biz_name.required) errors.push("Business Name is required.");
      return errors;
    },
    contentErrors() {
      const errors = [];
      if (!this.$v.content.$dirty) return errors;
      if (!this.$v.content.required) errors.push("Content is required.");
      if (!this.$v.content.minLength)
        errors.push("Content must be at least 20 characters.");
      return errors;
    },
  },
  methods: {
    async submit() {
      this.$v.$touch();
      this.sent = false;
      if (this.$v.$invalid) return;
      let text = "Thanks for your inquiry. We will get back to you soon.";
      text += `\n\n\nName: ${this.name}\nPhone: ${this.phone}\nEmail: ${this.email}]`;
      text += `\nBusiness Name: ${this.biz_name}`;
      text += `\n\nMessage: ${this.content}`;
      const data = {
        to: this.email,
        subject: this.subject || "POS Inquiry",
        text,
        name: this.name,
        phone: this.phone,
        email: this.email,
        biz_name: this.biz_name,
      };
      this.loading = true;
      try {
        await this.axios.post("/email/posInquiry", data);
        this.alertType = "success";
        this.alertText = "Inquiry has been sent. We will get back to you soon.";
      } catch (e) {
        this.alertType = "error";
        this.alertText = `Failed to send inquiry. Please email us at pos@selflane.com. Error message: ${e.response?.data}`;
      }
      this.sent = true;
      this.loading = false;
    },
  },
};
</script>
