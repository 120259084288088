<template lang="pug">
v-dialog(v-model="dialog", width="600", scrollable)
  v-form(@submit.prevent="submit")
    v-card
      v-toolbar(flat, dense)
        .subtitle-2 Edit Suffix Group
        v-spacer
        v-btn.mr-3(
          icon,
          title="sort choice alphabetically",
          @click="sortByName()"
        )
          v-icon mdi-sort-alphabetical-descending
        v-btn.text-notransform(
          outlined,
          small,
          @click.stop="more()",
          color="secondary"
        )
          v-icon(small) mdi-plus
          span Item
      v-card-text.py-0
        v-text-field(
          label="Group Title",
          v-model="suffix.title",
          autofocus,
          ref="focus"
        )
        simple-table.mb-3
          thead
            tr
              th Name
              th Kitchen Name
              th Price/Unit
              th
          tbody
            tr(
              v-for="(item, index) in suffix.items",
              :key="index",
              draggable="true",
              @dragstart="drag(index, $event)",
              @dragend="dragend($event)",
              @drop="drop(index, $event)",
              @dragover.prevent=""
            )
              td
                v-text-field(
                  v-model.trim="item.name",
                  hide-details,
                  single-line,
                  dense
                )
              td
                v-text-field(
                  v-model.trim="item.kName",
                  hide-details,
                  single-line,
                  dense
                )
              td
                v-text-field(
                  type="number",
                  v-model="item.price",
                  hide-details,
                  single-line,
                  prefix="$",
                  dense
                )
              td.text-right
                .d-flex.flex-row.align-center
                  v-icon.mr-3(color="error", small, @click.stop="less(index)") mdi-delete
                  v-icon(small) mdi-menu
        v-text-field(
          v-model="names",
          persistent-hint,
          hint="Add names separated by a comma",
          placeholder="Option A, Option B, Option C",
          @keydown.enter.prevent="add()",
          @blur="add()",
          append-icon="chevron_right",
          @click:append="add",
          solo
        )
      v-card-actions
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import { EventBus } from "@/event-bus.js";
import _ from "underscore";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "MatrixModifierSuffixesForm",
  data() {
    return {
      names: "",
      suffix: {},
      dialog: false,
      loading: false,
    };
  },
  computed: { ...mapGetters(["biz"]) },
  mounted() {
    EventBus.$on("edit-matrix-modifier-suffixes", this.edit);
  },
  destroyed() {
    EventBus.$off("edit-matrix-modifier-suffixes", this.edit);
  },
  methods: {
    ...mapActions(["setMatrixModifier"]),
    sortByName() {
      this.suffix.items = _.sortBy(this.suffix.items, (o) => o.name);
    },
    more() {
      this.suffix.items.push({ name: "", kName: "", price: 0 });
    },
    add() {
      if (!this.names) return;
      const items = _.chain(this.names.split(","))
        .compact()
        .map((o) => {
          return { name: o.trim(), kName: "", price: 0 };
        })
        .value();
      this.suffix.items = this.suffix.items.concat(items);
      this.names = "";
    },
    less(index) {
      if (index < this.suffix.items.length && index >= 0) {
        this.suffix.items.splice(index, 1);
      }
    },
    drag(index, ev) {
      this.draggingIndex = index;
      ev.target.style.opacity = 0.5;
    },
    dragend: function (e) {
      e.target.style.opacity = 1;
    },
    drop: function (index) {
      if (!this.suffix.items) return;
      if (index == this.draggingIndex) return;
      if (index < 0 || index >= this.suffix.items.length) return;
      if (
        this.draggingIndex < 0 ||
        this.draggingIndex >= this.suffix.items.length
      )
        return;
      let items = JSON.parse(JSON.stringify(this.suffix.items));
      let tmp = JSON.parse(JSON.stringify(items[this.draggingIndex]));
      items.splice(this.draggingIndex, 1);
      items.splice(index, 0, tmp);
      this.suffix.items = items;
    },
    async submit() {
      if (!this.biz) return;
      _.each(this.suffix.items, (o) => {
        o.name = o.name?.trim();
        o.kName = o.kName?.trim();
      });
      this.suffix.items = this.suffix.items.filter((o) => o.name);
      const found = this.suffix.items.find((o) => o.price < 0);
      if (found) {
        this.$toast.error(found.name + " price cannot be negative");
        return;
      }
      this.loading = true;
      let params;
      if (this.suffix._id) {
        params = {
          criteria: { _id: this.biz._id, "suffixes._id": this.suffix._id },
          action: { $set: { "suffixes.$": this.suffix } },
        };
      } else {
        params = {
          criteria: { _id: this.biz._id },
          action: { $push: { suffixes: this.suffix } },
        };
      }
      try {
        const data = await this.$api.matrixModifier.update(params);
        this.setMatrixModifier(data);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    edit(data) {
      this.suffix = JSON.parse(JSON.stringify(data));
      this.errors = [];
      this.dialog = true;
      this.$nextTick(() => this.$refs.focus.focus());
    },
  },
};
</script>
