<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-form(@submit.prevent="submit")
    v-card(v-if="biz")
      v-toolbar(dense, flat)
        .subtitle-2 {{ biz.name }}
      v-card-text
        p A terminal location is a physical location where you can connect a reader to your Stripe account. You can create multiple locations for a single business.
        p Do you want to create a terminal location for this business?
        v-text-field(v-model="name", label="Location Name", required)
      v-card-actions
        v-btn(block, color="secondary", type="submit", :loading="loading") Create Location
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      biz: null,
      name: "",
      loading: false,
    };
  },
  methods: {
    open(biz) {
      if (!biz) {
        this.$toast.error("Business not provided");
        return;
      }
      this.biz = biz;
      this.name = "";
      this.dialog = true;
    },
    async submit() {
      if (!this.biz) return;
      this.name = this.name?.trim();
      this.loading = true;
      try {
        if (!this.name) throw new Error("Location name is required");
        const params = { bizId: this.biz._id, name: this.name };
        await this.$api.stripe.terminalLocation.create(params);
        this.$toast.success("Location created");
        this.$emit("created");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>