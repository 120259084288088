<template lang="pug">
v-card(flat, v-if="id", :loading="loading")
  v-toolbar(flat, dense)
    .subtitle-2 Payouts
    v-spacer
    YearSelector(@select="selectYear")
  v-card-text
    Balance(ref="balance")
  Payouts(:payouts="payouts", :year="year")
</template>

<script>
import Balance from "/libs/components/Stripe/Balance";
import Payouts from "/libs/components/Stripe/Payouts";

export default {
  components: { Balance, Payouts },
  props: {
    id: { type: String, default: null }, // stripe connected account id
  },
  data() {
    return {
      year: new Date().getFullYear(),
      payouts: [],
      loading: false,
    };
  },
  methods: {
    selectYear(year) {
      this.year = year;
      this.load();
    },
    async load() {
      this.payouts = [];
      if (!this.id) return;
      this.loading = true;
      try {
        const params = { destination: this.id, year: this.year };
        this.payouts = await this.$api.stripe.payout.list(params);
        this.$refs.balance?.load(this.id);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
