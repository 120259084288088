<template lang="pug">
v-card-text
  .s-vstack
    .subtitle-2 Last updated at {{ bizservice?.third_party?.last_sync | datetime }}
    .subtitle-2 {{ bizservice?.third_party?.message }}
    div
      v-btn(
        small,
        text,
        color="secondary",
        :href="link",
        target="_blank",
        v-if="link"
      )
        v-icon(small, left) mdi-link
        span.text-notransform Chowly Testing URL
    CombineMenus
    div
      SyncMenu(:item="bizservice", :x-small="false", small)
</template>

<script>
import { mapGetters } from "vuex";
import CombineMenus from "/libs/components/Biz/Chowly/CombineMenus";
import SyncMenu from "/libs/components/Biz/Chowly/SyncMenu";

export default {
  components: { SyncMenu, CombineMenus },
  computed: {
    ...mapGetters(["bizservice"]),
    link() {
      return this.bizservice?.third_party?.test_url;
    },
  },
};
</script>