<template lang="pug">
v-dialog(v-model="dialog", width="600", scrollable)
  v-form(@submit.prevent="submit")
    v-card(v-if="dish")
      v-toolbar(dense, flat)
        .subtitle-2 {{ title }}
      v-card-text.pt-0
        v-row(dense)
          v-col(cols="5")
            v-text-field(label="Name", v-model="dish.name", autofocus)
          v-col(cols="5")
            v-text-field(
              label="Kitchen Name (optional)",
              v-model="dish.abbr",
              persistent-hint,
              hint="Abbr/2nd language"
            )
          v-col(cols="2")
            v-text-field(
              type="number",
              min="0",
              label="Servings",
              v-model.number="dish.servings"
            )
          v-col(cols="6")
            v-text-field(label="SKU", v-model="dish.sku")
          v-col(cols="6")
            v-text-field(label="UPC", v-model="dish.upc")
        v-row(dense)
          v-col(cols="4")
            PriceField(v-model.number="dish.price")
          v-col(cols="4")
            PriceField(
              v-model.number="dish.onlineUpcharge",
              label="Online Upcharge"
            )
          v-col(cols="4")
            PriceField(
              v-model.number="dish.thirdPartyUpcharge",
              label="3rd Party Upcharge"
            )
        v-textarea(
          label="Description",
          placeholder="optional",
          v-model="dish.description",
          dense,
          rows="1"
        )
        v-select(
          label="Menus (allow multiple)",
          v-model="dish.menus",
          :items="menus",
          multiple,
          small-chips,
          deletable-chips,
          menu-props="auto"
        )
        v-select(
          label="Course (only one)",
          v-model="dish.course",
          :items="courses",
          small-chips,
          ,
          menu-props="auto"
        )
        v-row(dense)
          v-col(cols="8")
            v-select(
              label="Printer (allow multiple)",
              v-model="dish.prints",
              multiple,
              :items="printItems",
              item-text="title",
              small-chips,
              deletable-chips
            )
          v-col(cols="4")
            v-select(
              label="Printing Group",
              v-model="dish.printingGroup",
              :items="printingGroups"
            )
        v-select(
          label="Tags (allow multiple)",
          v-model="dish.tags",
          multiple,
          :items="tagItems",
          small-chips,
          deletable-chips,
          hint="Please only select appropriate options",
          persistent-hint
        )
        v-row(dense, wrap)
          v-col(cols="6")
            v-switch(
              label="Run out (86'd)",
              v-model="dish.ooo",
              color="red",
              hide-details,
              dense
            )
          v-col(cols="6")
            v-switch(
              label="In Store Only",
              v-model="dish.hide",
              color="red",
              hide-details,
              dense
            )
          v-col(cols="6")
            v-switch(
              label="Sync to Third Party",
              v-model="dish.third_party",
              color="secondary",
              hide-details,
              dense
            )
        v-row(align="center", dense)
          v-col(cols="6")
            v-switch(
              v-model="isByWeight",
              label="Priced by weight",
              dense,
              color="secondary"
            )
          v-col(cols="6")
            v-select(
              dense,
              label="Unit of Measurement",
              v-model="dish.uom",
              :items="wUnits",
              v-if="isByWeight",
              hide-details
            )
        .red--text(v-for="(error, index) in errors", :key="index + 'index'") {{ error }}
        .mt-3
          div <b>Name</b> should be readable to customers. <b>Kitchen Name</b> can be a shorten name or a 2nd language name for kitchen use.
          div You can assign a dish to multiple menus and multiple printers but only to one course.
          div <b>Please add a picture</b> to the item if available.
          div <b>Online upcharge</b> is optional in case the online price should be adjusted from the in-store price.
          div <b>Third party upcharge</b> is optional in case you use our third-party integration and sync menu to all third-party platforms
      v-card-actions
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EditDishForm",
  data() {
    return {
      dish: null,
      dialog: false,
      errors: [],
      loading: false,
      tagItems: [
        { text: "Spicy Mild", value: "spicy-mild" },
        { text: "Spicy Medium", value: "spicy-medium" },
        { text: "Spicy Hot", value: "spicy-hot" },
        { text: "Gluten Free", value: "gf" },
        { text: "Vegan", value: "vegan" },
      ],
      isByWeight: false,
      wUnits: [
        { text: "Pound", value: "LB" },
        { text: "Ounce", value: "OZ" },
        { text: "Kilogram", value: "KG" },
        { text: "Gram", value: "GM" },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz", "printItems"]),
    title() {
      if (this.dish?._id) return "Edit Dish";
      return "Add Dish";
    },
    courses() {
      return (
        this.biz?.courses?.map((o) => {
          return { text: o.name, value: o._id };
        }) || []
      );
    },
    menus() {
      return (
        this.biz?.menus?.map((o) => {
          return { text: o.name, value: o._id };
        }) || []
      );
    },
    printingGroups() {
      return (
        this.biz?.printingGroups?.map((o) => {
          return { text: o.name, value: o._id };
        }) || []
      );
    },
  },
  methods: {
    open(data) {
      if (!data) return;
      this.dish = JSON.parse(JSON.stringify(data));
      this.errors = [];
      this.dialog = true;
      this.isByWeight = !!this.dish.uom;
    },
    async submit() {
      this.errors = [];
      this.dish.name = this.dish.name.trim();
      this.dish.price = this.dish.price || 0; // in case price is empty
      // remove empty prints
      this.dish.prints = this.dish.prints?.filter((o) => o !== null) || [];
      /// validate
      if (!this.biz || !this.dish) {
        this.errors = ["Invalid data"];
        return;
      }
      if (!this.isByWeight) this.dish.uom = null;
      if (!this.dish.name || this.dish.name.length === 0)
        this.errors.push("Name is required");
      const found = this.biz.dishes.find((o) => {
        return o._id !== this.dish._id && o.name === this.dish.name;
      });
      if (found) {
        this.errors.push("Duplicate name. A dish with the same name is found.");
      }
      if (this.errors && this.errors.length > 0) return;
      this.loading = true;
      let params;
      if (this.dish?._id) {
        params = {
          criteria: { _id: this.biz._id, "dishes._id": this.dish._id },
          action: { $set: { "dishes.$": this.dish } },
        };
      } else {
        params = {
          criteria: { _id: this.biz._id },
          action: { $push: { dishes: this.dish } },
        };
      }
      try {
        const data = await this.$api.biz.update(params);
        this.$store.dispatch("setBiz", data);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
