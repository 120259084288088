<template lang="pug">
v-card(flat, v-if="pos", height="100%")
  v-toolbar(flat, dense)
    .subtitle-2 Receipt Graphic Message
    v-spacer
    IconBtn(
      icon="mdi-delete",
      title="",
      @click="confirmRemove()",
      v-if="pos.epson.picBottom",
      color="error"
    )
  v-divider
  v-img(
    :src="mediaHost + pos.epson.picBottom + '?' + currentTime",
    v-if="pos.epson.picBottom"
  )
  v-card-text.s-vstack(v-if="!pos.epson.picBottom")
    croppa.mx-auto(
      v-model="myCroppa",
      :width="width",
      :height="height",
      :quality="1",
      canvas-color="white",
      initial-size="contain"
    )
    v-slider.mt-6(
      v-model="width",
      thumb-label="always",
      :max="range.maxWidth",
      :min="range.minWidth",
      step="10",
      label="Width"
    )
    v-slider(
      v-model="height",
      thumb-label="always",
      :max="range.maxHeight",
      :min="range.minHeight",
      step="10",
      label="Height"
    )
    v-btn(@click="generateBlob", block, color="secondary", :loading="loading") Save
    .text-center Add a graphic message to the bottom of the receipt.
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      mediaHost: process.env.VUE_APP_MEDIAHOST,
      myCroppa: {},
      currentTime: "",
      loading: false,
      range: {
        maxWidth: 450,
        minWidth: 300,
        maxHeight: 500,
        minHeight: 80,
      },
      width: 420,
      height: 100,
    };
  },
  computed: {
    ...mapGetters(["pos"]),
  },
  methods: {
    ...mapActions(["setPOS"]),
    confirmRemove() {
      confirm("Would you like to delete this picture?") && this.remove();
    },
    async remove() {
      if (!this.pos || !this.pos._id) return;
      const params = { id: this.pos._id };
      try {
        const { data } = await this.axios.post("/pos/deleteBottomPic", params);
        this.setPOS(data);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    async generateBlob() {
      this.myCroppa.generateBlob(
        (blob) => {
          if (!blob) return this.myCroppa.chooseFile();
          this.upload(blob);
        },
        "image/jpeg",
        0.8
      );
    },
    async upload(blob) {
      if (!this.pos || !this.pos._id) return;
      this.loading = true;
      const id = this.pos._id;
      const formData = new FormData();
      formData.append("file", blob);
      formData.append("id", id);
      try {
        const url = "/pos/updateBottomPic";
        const { data } = await this.axios.post(url, formData);
        this.setPOS(data);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    delay() {
      this.currentTime = Date.now();
    },
  },
};
</script>
