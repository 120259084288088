<template lang="pug">
v-btn(icon, @click.stop="handleRemove", :loading="loading")
  v-icon.red--text(small) mdi-delete
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return { loading: false };
  },
  computed: { ...mapGetters(["biz"]) },
  methods: {
    handleRemove() {
      confirm("Do you want to delete this course?") && this.remove();
    },
    async remove() {
      if (!this.item || !this.item._id || !this.biz) return;
      this.loading = true;
      try {
        const action = { $pull: { courses: { _id: this.item._id } } };
        const result = await this.$api.biz.put(this.biz._id, action);
        this.$store.dispatch("setBiz", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
