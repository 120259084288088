<template lang="pug">
v-dialog(v-model="dialog", width="450")
  template(v-slot:activator="{ on }")
    v-btn.text-notransform.mx-1(
      outlined,
      small,
      color="secondary",
      @click="load()",
      v-on="on"
    )
      span Edit Dish Markers
      v-icon(small, right) mdi-pencil
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Dish Markers
      v-spacer
      IconBtn(@click.stop="more()", title="Item", icon="mdi-plus")
    v-divider
    v-form(@submit.prevent="save")
      simple-table(v-if="status")
        thead
          tr
            th Name
            th Kitchen Name (optional)
            th
        tbody
          tr(v-for="(item, index) in dishes", :key="index")
            td
              v-text-field(
                dense,
                v-model.trim="item.name",
                hide-details,
                single-line,
                @keydown.down="more()"
              )
            td
              v-text-field(
                dense,
                v-model.trim="item.abbr",
                hide-details,
                single-line,
                @keydown.down="more()"
              )
            td
              v-btn(icon, small, @click.stop="less(index)", tabindex="-1")
                v-icon(color="red") mdi-close
      v-card-text
        v-checkbox(v-model="status", label="Use Markers", color="secondary")
        v-btn(type="submit", block, color="secondary", :loading="loading") Save
        .body-2.mt-3 Used in Point of Sale. Kitchen Name is optional and can be an abbreviation or a 2nd language name.
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      status: true,
      dishes: [],
    };
  },
  computed: { ...mapGetters(["biz"]) },
  methods: {
    async load() {
      this.dishes = [];
      if (!this.biz) return;
      const criteria = { biz: this.biz._id };
      const result = await this.$api.menu.marker.retrieve({ criteria });
      this.dishes = result?.dishes || [];
      this.status = result?.status;
    },
    more() {
      this.dishes.push({ name: "", abbr: "" });
    },
    less(index) {
      this.dishes.splice(index, 1);
    },
    async save() {
      if (!this.biz) return;
      this.loading = true;
      const dishes = this.dishes
        ?.filter((o) => o.name)
        ?.map((o) => {
          return { name: o.name, abbr: o.abbr, indic: true, ul: true };
        });
      const params = { biz: this.biz._id, dishes: dishes, status: this.status };
      try {
        await this.$api.menu.marker.create(params);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
