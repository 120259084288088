<template lang="pug">
.range-box
  v-menu(offset-y, v-if="monthSelector")
    template(v-slot:activator="{ on }")
      v-btn(text, v-on="on")
        span.text-notransform {{ selectedMonth }}
        v-icon(right) mdi-chevron-down
    v-card(max-height="400", width="120")
      v-list.py-0(dense)
        v-list-item(
          v-for="(item, index) in months",
          :key="index",
          @click="select(item.value)"
        )
          v-list-item-content
            v-list-item-title {{ item.text }}
  v-menu(open-on-hover, offset-y, v-if="selector")
    template(v-slot:activator="{ on }")
      v-btn(text, v-on="on")
        span.text-notransform {{ selected }}
        v-icon(right) mdi-chevron-down
    v-list.py-0(dense)
      v-list-item(
        v-for="(item, index) in items",
        :key="index + 'item'",
        @click="select(item.value)"
      )
        v-list-item-content
          v-list-item-title {{ item.text }}
  .box-divider
  v-menu(
    :close-on-content-click="false",
    offset-y,
    :nudge-right="40",
    v-model="dateDialog"
  )
    template(v-slot:activator="{ on }")
      .d-flex.flex-row.align-center.py-1(v-on="on", role="button")
        v-icon.ml-3(small) mdi-calendar
        .subtitle-2.mx-3 {{ rangeText }}
        v-progress-circular.mr-3(
          indeterminate,
          size="16",
          width="1",
          color="secondary",
          v-if="loading"
        )
    v-date-picker(
      v-model="content",
      range,
      scrollable,
      background-color="error",
      :max="maxDate",
      :min="minDate",
      @change="select()"
    )
</template>
<script>
import moment from "moment-timezone";
import _ from "underscore";

export default {
  name: "DateRangePicker",
  props: {
    selector: { type: Boolean, default: false },
    monthSelector: { type: Boolean, default: false },
    value: { type: Array, default: () => [] }, // ['YYYY-MM-DD', 'YYYY-MM-DD']
    minDate: { type: String, default: "2016-01-01" },
    maxDate: { type: String, default: moment().format("YYYY-MM-DD") },
    startYear: {
      type: Number,
      default: 2017,
    },
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      content: this.value || [
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
      dateDialog: false,
    };
  },
  computed: {
    rangeText() {
      if (!this.content || !this.content.length) return "";
      if (this.content.length == 1) {
        return moment(this.content[0]).format("MMM D, YYYY");
      }
      const beginYear = moment(this.content[0]).format("YYYY");
      const endYear = moment(this.content[1]).format("YYYY");
      const thisYear = moment().format("YYYY");
      let begin = moment(this.content[0]).format("MMM D");
      let end = moment(this.content[1]).format("MMM D");
      if (beginYear != thisYear) begin = begin + ", " + beginYear;
      if (endYear != thisYear) end = end + ", " + endYear;
      if (this.content[0] == this.content[1]) return begin;
      return begin + "\u2014" + end;
    },
    valid() {
      return this.content && this.content.length == 2;
    },
    color() {
      if (this.valid) return "secondary";
      return "error";
    },
    items() {
      return [
        { text: "Today", value: this.today },
        { text: "Yesterday", value: this.yesterday },
        { text: "This Week", value: this.this_week },
        { text: "Last Week", value: this.last_week },
        { text: "This Month", value: this.this_month },
        { text: "Last Month", value: this.last_month },
        { text: "This Year", value: this.this_year },
        { text: "Last Year", value: this.last_year },
      ];
    },
    selected() {
      const content = this.content.join(",");
      if (content == this.today.join(",")) return "Today";
      if (content == this.yesterday.join(",")) return "Yesterday";
      if (content == this.this_week.join(",")) return "This Week";
      if (content == this.last_week.join(",")) return "Last Week";
      if (content == this.this_month.join(",")) return "This Month";
      if (content == this.last_month.join(",")) return "Last Month";
      if (content == this.this_year.join(",")) return "This Year";
      if (content == this.last_year.join(",")) return "Last Year";
      return "Custom";
    },
    months() {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      let year = this.startYear;
      let items = [];
      while (year < currentYear) {
        _.each(_.range(1, 13), (m) =>
          items.push(year + "-" + String(m).padStart(2, "0"))
        );
        year++;
      }
      let month = 1;
      while (month <= currentMonth) {
        items.push(currentYear + "-" + String(month).padStart(2, "0"));
        month++;
      }
      items.reverse();
      return items.map((o) => {
        return {
          text: o,
          value: [
            moment(o, "YYYY-MM").format("YYYY-MM-DD"),
            moment(o, "YYYY-MM").endOf("month").format("YYYY-MM-DD"),
          ],
        };
      });
    },
    selectedMonth() {
      if (this.content && this.content.length == 2) {
        const month = moment(this.content[0]).format("MMM YYYY");
        const validBegin =
          moment(month, "MMM YYYY").format("YYYY-MM-DD") == this.content[0];
        const validEnd =
          moment(month, "MMM YYYY").endOf("month").format("YYYY-MM-DD") ==
          this.content[1];
        if (validBegin && validEnd) return "Month";
      }
      return "Custom";
    },
    today() {
      return [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
    },
    yesterday() {
      return [
        moment().subtract(1, "days").format("YYYY-MM-DD"),
        moment().subtract(1, "days").format("YYYY-MM-DD"),
      ];
    },
    this_week() {
      return [
        moment().startOf("isoWeek").format("YYYY-MM-DD"),
        moment().endOf("isoWeek").format("YYYY-MM-DD"),
      ];
    },
    last_week() {
      return [
        moment().subtract(1, "weeks").startOf("isoWeek").format("YYYY-MM-DD"),
        moment().subtract(1, "weeks").endOf("isoWeek").format("YYYY-MM-DD"),
      ];
    },
    this_month() {
      return [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ];
    },
    last_month() {
      return [
        moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD"),
        moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD"),
      ];
    },
    this_year() {
      return [
        moment().startOf("year").format("YYYY-MM-DD"),
        moment().endOf("year").format("YYYY-MM-DD"),
      ];
    },
    last_year() {
      return [
        moment().subtract(1, "years").startOf("year").format("YYYY-MM-DD"),
        moment().subtract(1, "years").endOf("year").format("YYYY-MM-DD"),
      ];
    },
  },
  watch: {
    value() {
      this.content = this.value;
    },
    content(newval, oldval) {
      if (newval == oldval) return;
      this.content = this.content.sort((a, b) => moment(a) - moment(b));
      this.$emit("input", this.content);
    },
  },
  methods: {
    select(value) {
      if (value) this.content = value;
      if (!this.valid) {
        this.$toast.error("Please select a valid date range");
        return;
      }
      this.dateDialog = false;
      this.content = this.content.sort((a, b) => moment(a) - moment(b));
      this.$emit("input", this.content);
      this.$emit("select", this.content);
    },
  },
};
</script>

<style lang="sass" scoped>
.range-box
  display: flex
  align-items: center
  overflow-x: auto
  border-radius: 6px
  border: 1px solid #e4e4e4
  background-color: white

.box-divider
  background-color: #e4e4e4
  width: 1px
  height: 100%
</style>