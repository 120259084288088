// Toggle Dish Online Availability
<template lang="pug">
v-btn(
  icon,
  @click.stop="toggle(item)",
  v-if="item",
  title="Online Availability",
  :loading="loading"
)
  v-icon(:color="color") mdi-earth
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["item"],
  data() {
    return { loading: false };
  },
  computed: {
    color() {
      if (this.item && this.item.status) return "success";
      else return "grey lighten-1";
    },
  },
  methods: {
    ...mapActions(["updateEvent"]),
    async toggle() {
      this.loading = true;
      const val = this.item.status == true;
      const params = {
        event_id: this.item._id,
        action: { $set: { status: !val } },
      };
      try {
        const result = await this.$api.event.entry.update(params);
        this.updateEvent(result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
